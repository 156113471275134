<template>
  <div class="smart-traffic">
    <tabs :list="tablist" v-model="sel"></tabs>
    <div class="main">
      <vehicle v-if="sel === 0"></vehicle>
    </div>
    <ourserviceBtnVue/>
  </div>
</template>

<script>
import vehicle from "./vehicle.vue";
import tabs from "/src/components/tabs/index.vue";
import ourserviceBtnVue from "../../../components/service/ourservice-btn.vue";
export default {
  components: { vehicle, tabs, ourserviceBtnVue },
  data() {
    return {
      sel: 0,
      tablist: [
        {
          title: "AVC Platform",
          header: "Autonomous Vehicle Cloud Platform",
          context:
            "Autonomous Vehicle, Algorithm Training, Algorithm Testing, Real-time on Road Monitor",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.smart-traffic {
  display: flex;
  width: 100%;
  height: 100%;
  background: url("../../../assets/img/smart_traffic.png") center center;
  background-size: cover;
  .main {
    flex: 1;
    height: 100%;
  }
  .height-light {
    width: 100%;
    height: 18.75px;
    background: rgba(249, 0, 0, 0.3);
  }
}
</style>
