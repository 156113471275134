<template>
  <div class="auto-vehicle">
    <div class="header">
      <img :src="require('/src/assets/img/service/auto-vehicle.png')" />
    </div>
    <div class="main">
      <div class="block">
        <div class="top">PHASE 1</div>
        <div class="center">
          Consolidation of back-end real-time data from Security Sentry system,
          importing these data to the Digital Twin Platform, result into
          panoramic information to the digital twin system.
        </div>
      </div>
      <div
        class="block"
        style="margin: 0 2.1vw;
}"
      >
        <div class="top">PHASE 2</div>
        <div class="center">
          Grant access to companies that provides autonomous driving algorithm,
          leverage on the massive real-time traffic data, fine-tune and verify
          the performance of the system.
        </div>
      </div>
      <div class="block">
        <div class="top">PHASE 3</div>
        <div class="center">
          Provide unmanned vehicle fine-tuning technology, test and verify the
          reliability of the vehicle, assist readiness of the autonomous driving
          algorithm. Provides real-time tracking and verification of the
          performance of the algorithm, thus foster the development of
          autonomous vehicle test and operating ecology.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mobi",
};
</script>

<style lang="less" scoped>
.auto-vehicle {
  display: flex;
  font-size: 1.94vh;
  font-family: Helvetica, Helvetica-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 2.36vh;
  letter-spacing: 0.12vh;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 25px 60px;
  .main {
    display: flex;
    padding-top:2.17vh;
    .block {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .top {
        font-family: PingFang SC, PingFang SC-Regular;
        color: #e02020;
        line-height: 2.78vh;
        margin-bottom: 0.76vh;
      }
    }
  }
  .header {
    width: 100%;
    position: relative;

    > img {
      width: 100%;
    }
  }
}
</style>
